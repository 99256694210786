<template>
  <div class="add-new-product">
    <title-bar
      submitBtn
      submitBtnTitle="Save product"
      title="Update product"
      @submitPressed="submitPressed"
    />
    <product-form
      v-if="product"
      :productObject="product"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ProductForm from '@/views/Admin/Products/components/ProductForm.vue'
import router from '@/router'

export default {
  components: {
    TitleBar,
    ProductForm,
  },
  data() {
    return {
      action: null,
      product: null,
    }
  },
  created() {
    this.loadProduct()
  },
  methods: {
    loadProduct() {
      const { id } = router.currentRoute.params
      this.$ProductVariants.getResource({ id }).then(response => {
        const productVariant = response.data
        this.$Products
          .getResourceByUrl({ url: productVariant.product })
          .then(productResponse => {
            const product = productResponse.data
            this.product = {
              productId: product.id,
              productVariantId: productVariant.id,
              category: product.category,
              url: product.url,
              name: productVariant.name,
              enabled: productVariant.enabled,
              price: productVariant.price,
              originalPrice: productVariant.originalPrice,
              depositPrice: productVariant.depositPrice || 0,
              product: productVariant.product,
              file: productVariant.file,
              fileUrl: productVariant.fileUrl,
              sku: productVariant.sku || null,
              variantSets: productVariant.variantSets || []
            }
          })
      })
    },
    submitPressed() {
      this.action = 'update'
    },
  },
}
</script>
