<template>
    <validation-observer ref="productForm" tag="form" @submit.prevent="onSubmit">
        <image-upload :preview-image="fileURL" @fileUploaded="setPhoto"/>
        <b-form>
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        class="row"
                        label="Name"
                        label-for="name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Name"
                            vid="product.name"
                        >
                            <b-form-input
                                v-model="product.name"
                                id="name"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <div class="dropdown-container">
                        <div class="main-dropdown">
                            <b-form-group
                                class="row"
                                label="Category"
                                label-for="category"
                                label-cols-md="2"
                                content-cols-lg="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    rules="required"
                                    name="category"
                                    vid="category"
                                >
                                    <v-select
                                        v-model="product.category"
                                        :options="categories"
                                        class="custom-select"
                                        id="category"
                                        :clearable="false"
                                        :searchable="false"
                                        :state="errors.length > 0 ? false : null"
                                    >
                                    </v-select>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </div>
                    </div>

                    <b-form-group
                        class="row"
                        label="Price"
                        label-for="price"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Price"
                            vid="product.price"
                        >
                            <b-form-input
                                v-model="product.price"
                                id="price"
                                @blur="presetOriginalPrice"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="Original price"
                        label-for="originalPrice"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="Original price"
                            vid="product.originalPrice"
                        >
                            <b-form-input
                                v-model="product.originalPrice"
                                id="originalPrice"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="Deposit price"
                        label-for="depositPrice"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules=""
                            name="Deposit price"
                            vid="product.depositPrice"
                        >
                            <b-form-input
                                v-model="product.depositPrice"
                                id="depositPrice"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="SKU"
                        label-for="sku"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <validation-provider
                            #default="{ errors }"
                            rules=""
                            name="SKU"
                            vid="product.sku"
                        >
                            <b-form-input
                                v-model="product.sku"
                                id="sku"
                                :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        label="Set"
                        label-for="set"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <div v-for="(vs, index) of product.variantSets" :key="index" class="variant-set-row">
                            <v-select
                                class="custom-select"
                                v-model="product.variantSets[index].productVariantId"
                                :options="productVariants"
                                :clearable="false"
                                :searchable="true"
                            />
                            <b-form-input class="variant-set-row-quantity" type="number" :min="0"
                                          v-model="product.variantSets[index].quantity"/>
                            <b-button @click="removeFromSet(index)" variant="primary">
                                <img src="@/assets/images/icons/close.svg"/>
                            </b-button>
                        </div>
                        <b-button variant="secondary" @click="addToSet">Add product variant to set</b-button>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        label="Enabled"
                        label-for="h-active"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-checkbox v-model="product.enabled" id="h-active" switch/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </validation-observer>
</template>

<script>
import {BRow, BCol, BFormGroup, BFormInput, BForm} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import Toast from '@/components/Toast.vue'
import ImageUpload from '@/components/Inputs/ImageUpload.vue'

export default {
    components: {
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        ImageUpload,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        productObject: {Object, required: true},
        action: {type: String, default: null},
    },
    data() {
        return {
            product: this.productObject,
            selectedCategory: null,
            categories: [],
            fileURL: null,
            apiUrl: this.$store.state.apiUrl,
            productVariants: [],
        }
    },
    watch: {
        action() {
            this.onSubmit()
        },
    },
    created() {
        let headers = {
            fields: '"/hydra:member/*/@id", "/hydra:member/*/name"',
        }
        this.$Categories
            .getCollection({params: {pagiantion: false}, headers})
            .then(response => {
                response.data['hydra:member'].forEach(category => {
                    this.categories.push({label: category.name, id: category['@id']})
                    if (
                        this.productObject.category &&
                        this.productObject.category === category['@id']
                    ) {
                        this.product.category = {
                            label: category.name,
                            id: category['@id'],
                        }
                    }
                })
            })

        headers = {
            fields: '"/hydra:member/*/id", "/hydra:member/*/name", "/hydra:member/*/isSet"',
        }
        this.$ProductVariants
            .getCollection({params: {pagination: false}, headers})
            .then(response => {
                response.data['hydra:member'].forEach(pv => {

                    if (
                        (this.product.productId !== pv.id || !this.product.productId) && !pv.isSet
                    ) {
                        this.productVariants.push({label: pv.name, id: pv.id})
                    }
                })
                if (this.product.variantSets && this.product.variantSets.length) {
                    this.product.variantSets.forEach(vs => {
                        const variant = this.productVariants.find(v => v.id === vs.productVariantId)
                        if (variant) {
                            vs.productVariantId = {label: variant.label, id: variant.id}
                        }
                    })
                }
            })

        if (this.product.fileUrl) {
            this.fileURL = `${this.product.fileUrl}`
        }
    },
    methods: {
        setPhoto(data) {
            this.product.file = data['@id']
            this.fileURL = data.url
        },
        presetOriginalPrice() {
            if (this.product.price && !this.product.originalPrice) {
                this.product.originalPrice = this.product.price
            }
        },
        async onSubmit() {
            const isValid = await this.$refs.productForm.validate()
            if (isValid) {
                const body = {
                    price: this.product.price.toString().replace(/,/g, '.'),
                    originalPrice: this.product.originalPrice
                        .toString()
                        .replace(/,/g, '.'),
                    depositPrice: this.product.depositPrice.toString().replace(/,/g, '.'),
                    position: this.product.position,
                    name: this.product.name,
                    file: this.product.file,
                    sku: this.product.sku,
                    enabled: this.product.enabled,
                    product: {
                        status: this.product.status,
                        category: this.product.category.id,
                        name: this.product.name,
                    },
                    variantSets: []
                }
                if (this.product.enabled) {
                    body.enabled = this.product.enabled
                }
                if (this.product.variantSets && this.product.variantSets.length) {
                    this.product.variantSets.forEach(vs => {
                        body.variantSets.push({
                            productVariantId: vs.productVariantId.id,
                            quantity: parseInt(vs.quantity, 10)
                        })
                    })
                }

                if (this.action === 'add') {
                    this.$ProductVariants
                        .createResource({body})
                        .then(productVariantResponse => {
                            if (productVariantResponse.status === 201) {
                                this.$helper.showToast(
                                    this.$toast,
                                    Toast,
                                    'New product created',
                                    '',
                                    'success',
                                )
                                this.$router.push(`/products`)
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                this.$emit('clearAction')
                                this.$helper.showToast(
                                    this.$toast,
                                    Toast,
                                    error.response.data['hydra:title'],
                                    error.response.data['hydra:description'],
                                    'danger',
                                )
                            }
                        })
                }

                if (this.action === 'update') {
                    this.$ProductVariants
                        .updateResource({
                            id: this.product.productVariantId,
                            body,
                        })
                        .then(productVariantResponse => {
                            if (productVariantResponse.status === 200) {
                                this.$helper.showToast(
                                    this.$toast,
                                    Toast,
                                    'Product updated',
                                    '',
                                    'success',
                                )
                                this.$router.push(`/products`)
                            }
                        })
                        .catch(error => {
                            if (error.response) {
                                this.$emit('clearAction')
                                this.$helper.showToast(
                                    this.$toast,
                                    Toast,
                                    error.response.data['hydra:title'],
                                    error.response.data['hydra:description'],
                                    'danger',
                                )
                            }
                        })
                }
            } else {
                this.$emit('clearAction')
            }
        },
        addToSet() {
            this.product.variantSets.push({
                productVariantId: null,
                quantity: 1
            })
        },
        removeFromSet(index) {
            this.product.variantSets.splice(index, 1)
        }
    },
}
</script>
