var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"productForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('image-upload',{attrs:{"preview-image":_vm.fileURL},on:{"fileUploaded":_vm.setPhoto}}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"row",attrs:{"label":"Name","label-for":"name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Name","vid":"product.name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.product.name),callback:function ($$v) {_vm.$set(_vm.product, "name", $$v)},expression:"product.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"dropdown-container"},[_c('div',{staticClass:"main-dropdown"},[_c('b-form-group',{staticClass:"row",attrs:{"label":"Category","label-for":"category","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"category","vid":"category"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select",attrs:{"options":_vm.categories,"id":"category","clearable":false,"searchable":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.product.category),callback:function ($$v) {_vm.$set(_vm.product, "category", $$v)},expression:"product.category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{staticClass:"row",attrs:{"label":"Price","label-for":"price","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Price","vid":"product.price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","state":errors.length > 0 ? false : null},on:{"blur":_vm.presetOriginalPrice},model:{value:(_vm.product.price),callback:function ($$v) {_vm.$set(_vm.product, "price", $$v)},expression:"product.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Original price","label-for":"originalPrice","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Original price","vid":"product.originalPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"originalPrice","state":errors.length > 0 ? false : null},model:{value:(_vm.product.originalPrice),callback:function ($$v) {_vm.$set(_vm.product, "originalPrice", $$v)},expression:"product.originalPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Deposit price","label-for":"depositPrice","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"","name":"Deposit price","vid":"product.depositPrice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"depositPrice","state":errors.length > 0 ? false : null},model:{value:(_vm.product.depositPrice),callback:function ($$v) {_vm.$set(_vm.product, "depositPrice", $$v)},expression:"product.depositPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"SKU","label-for":"sku","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"","name":"SKU","vid":"product.sku"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sku","state":errors.length > 0 ? false : null},model:{value:(_vm.product.sku),callback:function ($$v) {_vm.$set(_vm.product, "sku", $$v)},expression:"product.sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Set","label-for":"set","label-cols-md":"2","content-cols-lg":"4"}},[_vm._l((_vm.product.variantSets),function(vs,index){return _c('div',{key:index,staticClass:"variant-set-row"},[_c('v-select',{staticClass:"custom-select",attrs:{"options":_vm.productVariants,"clearable":false,"searchable":true},model:{value:(_vm.product.variantSets[index].productVariantId),callback:function ($$v) {_vm.$set(_vm.product.variantSets[index], "productVariantId", $$v)},expression:"product.variantSets[index].productVariantId"}}),_c('b-form-input',{staticClass:"variant-set-row-quantity",attrs:{"type":"number","min":0},model:{value:(_vm.product.variantSets[index].quantity),callback:function ($$v) {_vm.$set(_vm.product.variantSets[index], "quantity", $$v)},expression:"product.variantSets[index].quantity"}}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.removeFromSet(index)}}},[_c('img',{attrs:{"src":require("@/assets/images/icons/close.svg")}})])],1)}),_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.addToSet}},[_vm._v("Add product variant to set")])],2),_c('b-form-group',{staticClass:"row",attrs:{"label":"Enabled","label-for":"h-active","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"h-active","switch":""},model:{value:(_vm.product.enabled),callback:function ($$v) {_vm.$set(_vm.product, "enabled", $$v)},expression:"product.enabled"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }